@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes popIn {
  from {
    transform: translate3d(0, 0, 0) scale3d(1.0, 1.0, 1.0);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(-100px, -40px, 0) scale3d(0.85, 0.9, 0.85);
  }
}

.popIn {
  animation-name: popIn;
  will-change: transform, opacity;
}

@keyframes popOut {
  from {
    transform: translate3d(-70px, -30px, 0) scale3d(0.85, 0.9, 0.85);
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1.0, 1.0, 1.0);
  }
}

.popOut {
  animation-name: popOut;
  will-change: transform, opacity;
}

@keyframes cubeAnim {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.loader-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
  & .cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $core-link;
      animation: cubeAnim 2.4s infinite linear both;
      transform-origin: 100% 100%;
    }
  }
  & .cube2 {
    transform: scale(1.1) rotateZ(90deg);
    &:before {
      animation-delay: 0.3s;
    }
  }
  & .cube3 {
    transform: scale(1.1) rotateZ(180deg);
    &:before {
      animation-delay: 0.6s;
    }
  }
  & .cube4 {
    transform: scale(1.1) rotateZ(270deg);
    &:before {
      animation-delay: 0.9s;
    }
  }
}

.snappy {
  animation-duration: .8s !important;
  animation-timing-function: $core-transition-style !important;
  animation-delay: 0s !important;
}

.animated {
  animation-duration: .25s;
  animation-fill-mode: both;
  &.faster {
    animation-duration: 500ms;
  }
}
