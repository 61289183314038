.container-fluid {
  opacity: 0;
  transform: translate3d(-70px, -30px, 0) scale3d(0.85, 0.9, 0.85);
}

.table {
  color: $core-text;
  background-color: $core-modal;
}

.collapsing {
  transition: none !important;
}

// Needs refactor

.top-nav {
  background-color: $core-nav-background;
  margin: 0 !important;
  width: 100%;
  padding: 10px;
  z-index: 1050;
  li a {
    color: $core-text;
    padding: 0 15px;
    text-decoration: none;
    transition: color 0.3s;
    will-change: color;
    @media (max-width: 640px) {
      font-size: 22px;
    }
    &:hover {
      color: $core-link;
    }
    &:active {
      color: $core-text;
    }
  }
  & .admin {
    font-weight: bold;
    color: $core-link;
  }
}

#result-wrapper {
  background-color: $core-background;
  padding: 15px 15px;
  position: fixed;
  top: 58px;
  right: 0;
  transform: translateX(100%);
  transition: transform $core-transition;
  will-change: transform;
  z-index: 1050;
  @media (min-width: 768px) {
    height: calc(100vh - 58px);
  }
  @media (max-height: 770px) {
    overflow-y: auto;
  }
  @media screen {
    @media (min-width: 640px) and (max-width: 767px) {
      height: calc(100vh - 194px);
      overflow-y: auto;
      top: 194px;
    }
    @media (min-width: 320px) and (max-width: 639px) {
      height: calc(100vh - 230px);
      overflow-y: auto;
      top: 230px;
    }
  }
}

#result.list-group {
  opacity: 0;
}

#result-head {
  color: $core-text;
  font-size: 2em;
  text-transform: uppercase;
}

#result-close {
  color: $core-text;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 22px;
}

#result-wrapper li {
  background-color: $core-tile;
  box-sizing: border-box;
  display: block;
  opacity: 0.5;
  overflow: hidden;
  transition: $core-transition-simple;
  position: relative;
  &:hover {
    opacity: 1;
  }
  & .char-button {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    cursor: pointer;
  }
  height: 50px;
  margin: 5px 0;
  & img {
    width: 100px;
    mask-image: linear-gradient(to right, black 60%, transparent);
    position: relative;
    top: -30px;
  }
  & span {
    &.badge-container {
      position: absolute;
      top: -3px;
      z-index: 0;
    }
    &:not(.badge-container):not(.badge) {
      color: $core-text;
      font-size: $item-text-size;
      position: absolute;
      top: 8px;
      padding: 5px 5px;
      z-index: 1;
    }
  }
}

.tile-container {
  background-color: $tile-list-background;
  padding: 5px 10px;
  margin: 5px 0;
  & h2 {
    text-align: right;
  }
}

.tile-list {
  padding: 0;
  margin: 0;
}

.tile {
  background-color: $core-tile;
  box-sizing: border-box;
  display: block;
  opacity: 0.5;
  overflow: hidden;
  transition: $core-transition-simple;
  position: relative;
  &:hover {
    opacity: 1;
  }
  & .char-button, & a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    cursor: pointer;
  }
  &.big {
    height: 80px;
    width: 100%;
    margin: 5px 0;
    & img {
      height: 350px;
      position: absolute;
      top: -65px;
      left: -150px;
      mask-image: linear-gradient(to right, black 50%, transparent);
    }
    & h2 {
      font-size: $title-text-size;
      position: absolute;
      margin: 0;
      bottom: 5px;
      right: 5px;
    }
  }
  &.small {
    height: 50px;
    margin: 5px 0;
    & img {
      width: 100px;
      mask-image: linear-gradient(to right, black 60%, transparent);
      position: relative;
      top: -30px;
    }
    & span {
      &.badge-container {
        position: absolute;
        top: -1px;
        z-index: 0;
      }
      &:not(.badge-container):not(.badge) {
        color: $core-text;
        font-size: $item-text-size;
        position: absolute;
        top: 8px;
        padding: 5px 5px;
        z-index: 1;
      }
    }
  }
}

// -- Modal

.modal {
  & .modal-title, & .modal-body .col, & .close {
    z-index: 1051;
  }
  & .modal-body {
    overflow: hidden;
    & li {
      font-weight: bold;
    }
    & .modal-body-episodes-list {
      background-color: rgba(92, 77, 67, 0.35);
      padding: 5px;
    }
  }
  & .modal-header {
    background-color: $core-background;
  }
  & .modal-background {
    z-index: 1050;
    overflow: hidden;
    position: absolute;
    right: 0;
    bottom: 0;
    & img {
      mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 70%, transparent);
      opacity: 0.25;
    }
  }
}

.modal-open {
  & .modal {
    overflow-y: visible !important;
  }
}

.scene-preview {
  max-height: 500px;
  transform: rotate(-90deg);
}

.aero {
  border: none;
  border-radius: 0;
  box-shadow: $core-shadow-style;
}

.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
